import React, { useState, useEffect } from 'react';
import { throttle, debounce } from 'lodash';
import './LoginPage.css'
import './SubstituteDashboard.css'
import AddSubstituteModal from './AddSubstituteModal'
import MultiSelectDropdown from './components/MultiSelectDropdown';
import Cookies from 'js-cookie'

function RequestedSubstitutesDashboard() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState('');
  const [selectedSubstitute, setSelectedSubstitute] = useState({});

  const [manufacturers, setManufacturers] = useState({ all_fields: [] });
  const [vendors, setVendors] = useState({ all_fields: [] });

  const [expandedRow, setExpandedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [hasMoreData, setHasMoreData] = useState(true);

  const handleRowClick = async (itemId) => {
    const clickedItem = items.find(item => item.id === itemId);
    setSelectedItem(clickedItem);
    setExpandedRow(expandedRow === itemId ? null : itemId);
  };

  const handleAddSubstitute = () => {
    setIsModalOpen(true);
  };

  const closeModal = async () => {
    if (selectedItem) {
      setItems([]);  // reset items
      setPage(0);    // reset page
      setHasMoreData(true);
      fetchMoreData(0);
    }
    setIsModalOpen(false);
  };

  const renderAddSubstitutesModal = () => {
    if (isModalOpen) {
      return <AddSubstituteModal item={selectedItem} selectedSubstitute={selectedSubstitute} onClose={closeModal} noteType="SUBSTITUTE" />;
    }
    return null;
  };

  // TODO: we want to use redux for this
  useEffect(() => {
    const fetchUniqueValues = async (fieldName) => {
      try {
        const response = await fetch(`${apiUrl}/items/unique_values/${fieldName}`);
        const data = await response.json();

        if (fieldName === 'manufacturer_name') {
          setManufacturers(data);
        } else if (fieldName === 'vendor_name') {
          setVendors(data);
        }
      } catch (error) {
        console.error("Failed to fetch unique values:", error);
      }
    };

    fetchUniqueValues('manufacturer_name');
    fetchUniqueValues('vendor_name');
  }, []);

  const handleManufacturerChange = (arr) => {
    setSelectedManufacturer(arr[0]);
  };

  const handleVendorChange = (e) => {
    setSelectedVendor(e.target.value);
  };

  const handleScroll = throttle(() => {
    if (isFetching || !hasMoreData) return;

    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const bodyHeight = document.documentElement.scrollHeight;

    const scrolledToBottom = Math.ceil(scrollTop + windowHeight) >= bodyHeight;

    if (scrolledToBottom) {
      const skipValueForScrolling = page * 50;
      fetchMoreData(skipValueForScrolling);
    }
  }, 300);

  const debouncedSearch = debounce((value) => {
    setSearchTerm(value);
  }, 300);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isFetching]);

  useEffect(() => {
    setItems([]);  // reset items
    setPage(0);    // reset page
    setHasMoreData(true);
    fetchMoreData(0);
  }, [searchTerm, sortField, sortOrder, selectedManufacturer, selectedVendor]);

  const fetchMoreData = async (skipValue) => {
    if (isFetching) return; // Prevent duplicate fetches

    setIsFetching(true);
    const params = new URLSearchParams();
    
    params.append('user_id', Cookies.get("userId"));
    params.append('skip', skipValue);
    params.append('limit', 50);
    if (searchTerm) params.append('search_term', searchTerm);
    if (sortField) params.append('sort_by', sortField);
    if (selectedManufacturer) params.append('manufacturer_name', selectedManufacturer);
    if (selectedVendor) params.append('vendor_name', selectedVendor);
    params.append('sort_order', sortOrder);

    try {
        const endpoint = `${apiUrl}/substitutes/get_requested_substitutes?${params.toString()}`;
        const response = await fetch(endpoint);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const newData = await response.json();
        if (newData.items.length === 0) {
          setHasMoreData(false); // No more data to fetch
        }
        else {
          setItems(prevItems => [...prevItems, ...newData.items]); // Append new data
          setPage(prevPage => prevPage + 1); // Update the page number
        }
    
    } catch (error) {
        console.error("Fetching data failed:", error);
    } finally {
        setIsFetching(false);
    }
  };

  const handleHeaderClick = (field) => {
    if (sortField === field) {
      setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  return (
    <div className="add-substitute-wrapper">
      {renderAddSubstitutesModal()}
      <div className={isModalOpen ? 'no-display' : 'display-on'}>
        <input
          type="text"
          className="search-input"
          placeholder="Search"
          onChange={(e) => debouncedSearch(e.target.value)}
        />
        <div className="filter-section-2">
          <i className="fa fa-filter" aria-hidden="true"></i>
          <span>
            Filter by
          </span>
          <label className="filter-item-2">
            <select className="filter-dropdown-2">
              <option value="">ITEM TYPE</option>
            </select>
            {/* <i className="fa fa-angle-down" aria-hidden="true"></i> */}
          </label>
          <MultiSelectDropdown options={manufacturers.all_fields} onSelectionChange={handleManufacturerChange}/>
          {/* <label className="filter-item-2">
            <select className="filter-dropdown-2" value={selectedManufacturer} onChange={handleManufacturerChange}> 
              <option value="">MANUFACTURER (ALL)</option>
              {manufacturers.all_fields && manufacturers.all_fields.map(manufacturer => (
                <option key={manufacturer} value={manufacturer}>
                  {manufacturer}
                </option>
              ))}
            </select>
          </label> */}
          <label className="filter-item-2">
            <select className="filter-dropdown-2" value={selectedVendor} onChange={handleVendorChange}>
              <option value="">VENDOR (ALL)</option>
              {vendors.all_fields && vendors.all_fields.map(vendor => (
                <option key={vendor} value={vendor}>
                  {vendor}
                </option>
              ))}
            </select>
            {/* <i className="fa fa-angle-down" aria-hidden="true"></i> */}
          </label>
        </div>
        <div className="dashboard-table-wrapper">
          <table className="dashboard-table">
            <thead className="table-head">
              <tr className="table-row">
                <th onClick={() => handleHeaderClick('item_id')}>Item ID</th>
                <th onClick={() => handleHeaderClick('description')}>Description</th>
                <th onClick={() => handleHeaderClick('vendor_name')}>Vendor Name</th>
                <th onClick={() => handleHeaderClick('vendor_ref_number')}>Vendor Ref #</th>
                <th onClick={() => handleHeaderClick('mfr_name')}>MFR Name</th>
                <th onClick={() => handleHeaderClick('mfr_ref_number')}>MFR Ref #</th>
                <th onClick={() => handleHeaderClick('price')}>Price</th>
                <th onClick={() => handleHeaderClick('uom')}>UOM</th>
                <th onClick={() => handleHeaderClick('qoe')}>QOE</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {items.map(item => (
                <React.Fragment key={item.id}>
                  <tr onClick={() => handleRowClick(item.id)}>
                    <td className="truncate-text"><i className="fa fa-angle-right"></i>{item.internal_ref_no}</td>
                    <td className="truncate-text">{item.description_1}</td>
                    <td className="truncate-text">{item.vendor_name}</td>
                    <td className="truncate-text">{item.vendor_ref_no}</td>
                    <td className="truncate-text">{item.manufacturer_name}</td>
                    <td className="truncate-text">{item.manufacturer_ref_no}</td>
                    <td className="truncate-text">{(item.price_in_cents / 100).toFixed(2)}</td>
                    <td className="truncate-text">{item.uom}</td>
                    <td className="truncate-text">{item.quantity_of_each}</td>
                  </tr>
                  {expandedRow === item.id && (
                    <tr className="suggested-substitutes-row">
                      <td className="substitutes-td" colSpan="9">
                        <div className="suggested-substitutes-section">
                          <div className="substitute-header">
                            Suggested Substitute(s)
                          </div>
                          <table className="substitute-table">
                            <thead className="substitute-table-head">
                              <tr className="substitute-table-row">
                                <th onClick={() => handleHeaderClick('item_id')}>Item ID</th>
                                <th onClick={() => handleHeaderClick('description')}>Description</th>
                                <th onClick={() => handleHeaderClick('vendor_name')}>Vendor Name</th>
                                <th onClick={() => handleHeaderClick('vendor_ref_number')}>Vendor Ref #</th>
                                <th onClick={() => handleHeaderClick('mfr_name')}>MFR Name</th>
                                <th onClick={() => handleHeaderClick('mfr_ref_number')}>MFR Ref #</th>
                                <th onClick={() => handleHeaderClick('price')}>Price</th>
                                <th onClick={() => handleHeaderClick('uom')}>UOM</th>
                                <th onClick={() => handleHeaderClick('qoe')}>QOE</th>
                              </tr>
                            </thead>
                            <tr className="no-suggested-substitutes"> <td colSpan="9">
                              There are no suggested substitutes for this item yet.<button onClick={(e) => {
                                e.stopPropagation();
                                handleAddSubstitute();
                              }}>ADD NEW SUBSTITUTE
                              </button>

                            </td>
                            </tr>
                          </table>
                          {/* <div className="add-substitute-wrapper">
                            {suggestedSubstitutes[item._id] && (
                              <span className="no-suggested-substitutes">There are no suggested substitutes for this item yet.</span>
                            )}
                            <button onClick={(e) => {
                              e.stopPropagation();
                              handleAddSubstitute();
                            }}>ADD NEW SUBSTITUTE
                            </button></div> */}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>

        </div>
      </div>
    </div>

  );
}

export default RequestedSubstitutesDashboard;