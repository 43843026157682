import React, { useState, useEffect } from 'react';
import './NotesModal.css'
import Cookies from 'js-cookie';

function NotesModal(props) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { substituteId, noteType, onClose} = props;
    const [userNoteId, setUserNoteId] = useState('');
    const [noteHistory, setNoteHistory] = useState([]);
    const [textContent, setTextContent] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getUserNoteHistory = async () => {
            setIsLoading(true);
            try {
                const endpoint = `${apiUrl}/user_notes/get_user_note_history?substitute_id=${substituteId}`
                const response = await fetch(endpoint);
                const data = await response.json();
                if (data && data.status == "SUCCESS") { 
                    setUserNoteId(data.user_note_id);
                    setNoteHistory(data.history);
                    setTextContent(data.current_note_text);
                }
            } catch (error) {
                console.error("Error fetching user note history: ", error)
            } finally {
                setIsLoading(false);
            }
        }   
        getUserNoteHistory();
    }, [substituteId]); // Added substituteId as a dependency to refetch if orgId changes

    const closeNotesModal = (event) => {
        event.stopPropagation();
        // Check if the clicked target is the modal container, not the modal content
        if (event.target.className === 'notes-modal-container') {
            onClose();
        }
    };

    const editUserNote = async () => {
        const editorId = Cookies.get("userId");

        try {
            const requestBody = {
                user_note_id: userNoteId,
                note_type: noteType,
                last_edited_by_id: editorId,
                text_content: textContent,
            }
    
            const response = await fetch(`${apiUrl}/user_notes/edit_user_note`, {
                method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestBody)
            });
    
            // Check if the request was successful
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = response.json();
            if (data.status == "SUCCESS") {
                onClose();
            }
        }
        catch (error) {
            console.error("There was a problem with the editing the user note:", error.message);
            alert('Error during editing of note. Please try again.');
        }

    }

    const formatUtcToLocal = (utcDate) => {
        const localDate = new Date(utcDate);
        const formattedDate = localDate.toLocaleDateString(); // Gets only the date
        const formattedTime = localDate.toLocaleTimeString(); // Gets only the time
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
        return {
          date: formattedDate,
          timeAndZone: `${formattedTime} (${timeZone})`,
        };
      }
    
    const underlineStyle = { textDecoration: 'underline' };

    const renderNoteHistory = () => {
        return noteHistory.map((note, index) => (
            <div key={index} className="note-history-entry">
                <p>
                    {note.text_content} - 
                <span style={underlineStyle}> {note.edited_by_first_name} {note.edited_by_middle_name ? note.edited_by_middle_name + ' ' : ''}{note.edited_by_last_name} </span>
                    on <span style={underlineStyle}>
                        {formatUtcToLocal(note.edited_datetime).date}
                    </span> at {formatUtcToLocal(note.edited_datetime).timeAndZone}
                </p>
            </div>
        ));
    };
    
    return (
        <>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <div className="notes-modal-container" onClick={closeNotesModal}>
                    <div className="notes-modal-content">
                        <div className="panel-wrappers">
                            <h1>Notes</h1>
                            <p class="history-header">History</p>
                            <div class="notes-history-scrollable">
                                {renderNoteHistory()}
                            </div>
                            <div className="input-group-long">
                                <textarea placeholder="Add notes" value={textContent} onChange={e => setTextContent(e.target.value)}></textarea>
                            </div>
                            <div className="horz-bar"></div>
                            <div className="attachments-save-wrapper">
                                <button className="button" onClick={onClose}>Close</button>
                                <button className="button orange" onClick={editUserNote}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default NotesModal;
