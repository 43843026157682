import React, { useState, useEffect } from 'react';
import { throttle, debounce } from 'lodash';
import './SubstituteDashboard.css';
import AddSubstituteModal from './AddSubstituteModal';
import MultiSelectDropdown from './components/MultiSelectDropdown';
import NotesModal from './NotesModal';
import Cookies from 'js-cookie';

function SubstituteDashboard() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedManufacturer, setSelectedManufacturer] = useState('All');
  const [selectedVendor, setSelectedVendor] = useState('');
  const [selectedSubstitute, setSelectedSubstitute] = useState({});

  const [manufacturers, setManufacturers] = useState({ all_fields: [] });
  const [vendors, setVendors] = useState({ all_fields: [] });

  const [expandedRow, setExpandedRow] = useState(null);
  const [isAddSubstituteModalOpen, setIsAddSubstituteModalOpen] = useState(false);
  const [isRequestSubstituteModalOpen, setIsRequestSubstituteModalOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [suggestedSubstitutes, setSuggestedSubstitutes] = useState({}); // This will store an object with item IDs as keys and an array of substitutes as values.
  const [suggestedSubstitutesSupplementalInfo, setSuggestedSubstitutesSupplementalInfo] = useState({}); //stores object with substitute id as key and dict of substitute info as value

  const [sourcers, setSourcers] = useState([]);
  const [selectedSourcer, setSelectedSourcer] = useState(null);
  
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);


  const handleRowClick = async (itemId) => {
    const clickedItem = items.find(item => item.id === itemId);
    setSelectedItem(clickedItem);
    setExpandedRow(expandedRow === itemId ? null : itemId);
    if (expandedRow !== itemId) {
      await fetchSubstitutes(itemId);
      await getSubstituteSupplementalInfo(itemId);
    }
  };

  const handleAddSubstitute = () => {
    setIsAddSubstituteModalOpen(true);
  };  

  const handleRequestSubstitute = () => {
    setIsRequestSubstituteModalOpen(true);
  };

  const handleEditNote = () => {
    document.body.classList.add('no-scroll');
    setIsNotesModalOpen(true);
  }

  const closeModal = async () => {
    setIsAddSubstituteModalOpen(false);
    if (selectedItem) {
      await fetchSubstitutes(selectedItem.id);
      await getSubstituteSupplementalInfo(selectedItem.id);
    }
  };

  const closeNotesModal = async () => {
    document.body.classList.remove('no-scroll');
    setIsNotesModalOpen(false);
    if (selectedItem) {
      await fetchSubstitutes(selectedItem.id);
      await getSubstituteSupplementalInfo(selectedItem.id);
    }
  }

  useEffect(() => {    
    const fetchSourcers = async () => {
      const org_id = Cookies.get("organizationId");
       
      try {
        const response = await fetch(`${apiUrl}/users/get_sourcers?organization_id=${org_id}`);
        const data = await response.json();
        setSourcers(data.users);
      } catch (error) {
        console.error("Failed to fetch unique values:", error);
      }
    };

    if (isRequestSubstituteModalOpen) {
      fetchSourcers();
    }
  }, [isRequestSubstituteModalOpen]);

  const requestSubstitute = async (originalItemId) => {
    try {
      const requestedTime = new Date().toISOString();
      const requestBody = {
        original_item_id: originalItemId,
        requested_by_user_id: Cookies.get("userId"),
        requested_to_user_id: selectedSourcer, 
        requested_datetime: requestedTime,
      }

      const endpoint = `${apiUrl}/substitutes/request_substitute`

      const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.status === "SUCCESS") {
        alert("Added request successfully");
      }
    } catch (error) {
        console.error("There was an issue with requesting a substitute: ", error.message);
        alert('Error requesting substitute, please try again.');
    }
  }

  const getSubstituteSupplementalInfo = async (originalItemId) => {
    try {
      const endpoint = `${apiUrl}/substitutes/get_fulfilled_substitute_info?original_item_id=${originalItemId}`;
  
      const response = await fetch(endpoint);
      const data = await response.json();

      if (data && data.info) {
        const substituteInfoMap = data.info.reduce((acc, substituteInfo) => {
          acc[substituteInfo.substitute_id] = substituteInfo;
          return acc;
        }, {});

        setSuggestedSubstitutesSupplementalInfo(substituteInfoMap);
      }
    } catch (error) {
      console.error("Issue with getting substitute supplemental info: ", error.message);
      alert("Error loading substitute info, please try again");
    } 
  }
  
  const closeRequestedSubstituteModal = (event) => {
    // Check if the clicked target is the modal container, not the modal content
    if (event.target.className === 'request-modal-container') {
      setIsRequestSubstituteModalOpen(false);
    }
  };

  const fetchSubstitutes = async (originalItemId) => {
    try {
      const bearerToken = Cookies.get("jwtToken");
      const endpoint = `${apiUrl}/substitutes/get_substitutes?original_item_id=${originalItemId}`;
      const response = await fetch(endpoint, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`, 
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const substitutes = await response.json();
      setSuggestedSubstitutes(prevSubstitutes => ({
        ...prevSubstitutes,
        [originalItemId]: Array.isArray(substitutes.substitutes) ? substitutes.substitutes : []
      }));
    } catch (error) {
      console.error("Fetching substitutes failed:", error);
    }
  };

  const renderAddSubstitutesModal = () => {
    if (isAddSubstituteModalOpen) {
      return <AddSubstituteModal item={selectedItem} selectedSubstitute={selectedSubstitute} onClose={closeModal} />;
    }
    return null;
  };

  const renderNotesModal = () => {
    if (isNotesModalOpen) {
      return <NotesModal substituteId={selectedSubstitute.substitute_id} onClose={closeNotesModal} noteType="SUBSTITUTE" />;
    }
  }

  // TODO: we want to use redux for this
  useEffect(() => {
    const fetchUniqueValues = async (fieldName) => {
      try {
        const response = await fetch(`${apiUrl}/items/unique_values/${fieldName}`);
        const data = await response.json();

        if (fieldName === 'manufacturer_name') {
          setManufacturers(data);
        } else if (fieldName === 'vendor_name') {
          setVendors(data);
        }
      } catch (error) {
        console.error("Failed to fetch unique values:", error);
      }
    };

    fetchUniqueValues('manufacturer_name');
    fetchUniqueValues('vendor_name');
  }, []);

  const handleManufacturerChange = (arr) => {
    setSelectedManufacturer(arr[0]);
  };

  const handleVendorChange = (e) => {
    setSelectedVendor(e.target.value);
  };

  const handleSourcerChange = (e) => {
    setSelectedSourcer(e.target.value);
  };

  const handleScroll = throttle(() => {
    if (isFetching) return;

    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const bodyHeight = document.documentElement.scrollHeight;

    const scrolledToBottom = Math.ceil(scrollTop + windowHeight) >= bodyHeight;

    if (scrolledToBottom) {
      const skipValueForScrolling = page * 50;
      fetchMoreData(skipValueForScrolling);
    }
  }, 300);

  const debouncedSearch = debounce((value) => {
    setSearchTerm(value);
  }, 300);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isFetching]);

  useEffect(() => {
    const skipValue = 0;
    setItems([]);  // reset items
    setPage(0);    // reset page
    fetchMoreData(0);
  }, [searchTerm, sortField, sortOrder, selectedManufacturer, selectedVendor]);

  const fetchMoreData = async (skipValue) => {
    setIsFetching(true);

    const params = new URLSearchParams();

    params.append('skip', skipValue);
    params.append('limit', 50);
    if (searchTerm) params.append('search_term', searchTerm);
    if (sortField) params.append('sort_by', sortField);
    if (selectedManufacturer) params.append('manufacturer_name', selectedManufacturer);
    if (selectedVendor) params.append('vendor_name', selectedVendor);
    params.append('sort_order', sortOrder);

    try {
      const bearerToken = Cookies.get("jwtToken");
      const endpoint = `${apiUrl}/items/get_all_items?${params.toString()}`
      const response = await fetch(endpoint, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`, 
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const newData = await response.json();
      setItems(prevItems => [...prevItems, ...newData]);
      setPage(prevPage => prevPage + 1);
    } catch (error) {
      console.error("Fetching data failed:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleHeaderClick = (field) => {
    if (sortField === field) {
      setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };
  
  const formatUtcToLocal = (utcDate) => {
    const localDate = new Date(utcDate);
    const formattedDate = localDate.toLocaleDateString(); // Gets only the date
    const formattedTime = localDate.toLocaleTimeString(); // Gets only the time
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return {
      date: formattedDate,
      timeAndZone: `${formattedTime} (${timeZone})`,
    };
  }

  const underlineStyle = { textDecoration: 'underline' };
  
  return (
    <>
      <div className={isRequestSubstituteModalOpen ? "add-substitute-wrapper no-scroll" : "add-substitute-wrapper"}>
        {isRequestSubstituteModalOpen && (
          <div className="request-modal-container" onClick={closeRequestedSubstituteModal}>
            <div className="request-modal-content">
              <div className="request-modal-title">Request Substitute From:</div>
                <select className="filter-dropdown-2" value={selectedSourcer} onChange={handleSourcerChange}>
                  {sourcers && sourcers.map(sourcer => (
                    <option key={sourcer.id} value={sourcer.id}>
                      {sourcer.first_name} {sourcer.last_name}
                    </option>
                  ))}
                </select>
              <div className="horz-bar"></div>
              <div className="request-substitute-buttons">
                <button onClick={() => setIsRequestSubstituteModalOpen(false)}>Close</button>
                <button className="orange" onClick={() => requestSubstitute(selectedItem.id)}>Send Request</button>
              </div>
              <div className="x" onClick={() => setIsRequestSubstituteModalOpen(false)}>
                <div className="x-content">
                  X
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={isAddSubstituteModalOpen ? 'no-display' : 'display-on'}>
          <input
            type="text"
            className="search-input"
            placeholder="Search"
            onChange={(e) => debouncedSearch(e.target.value)}
          />
          <div className="filter-section-2">
            <i className="fa fa-filter" aria-hidden="true"></i>
            <span>
              Filter by
            </span>
            <label className="filter-item-2">
              <select className="filter-dropdown-2">
                <option value="">ITEM TYPE</option>
              </select>
            </label>
            <MultiSelectDropdown options={manufacturers.all_fields} onSelectionChange={handleManufacturerChange} />
            <label className="filter-item-2">
              <select className="filter-dropdown-2" value={selectedVendor} onChange={handleVendorChange}>
                <option value="">VENDOR (ALL)</option>
                {vendors.all_fields && vendors.all_fields.map(vendor => (
                  <option key={vendor} value={vendor}>
                    {vendor}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="dashboard-table-wrapper">
            <table className="dashboard-table">
              <thead className="table-head">
                <tr className="table-row">
                  <th onClick={() => handleHeaderClick('item_id')}>Item ID</th>
                  <th onClick={() => handleHeaderClick('description')}>Description</th>
                  <th onClick={() => handleHeaderClick('vendor_name')}>Vendor Name</th>
                  <th onClick={() => handleHeaderClick('vendor_ref_number')}>Vendor Ref #</th>
                  <th onClick={() => handleHeaderClick('mfr_name')}>MFR Name</th>
                  <th onClick={() => handleHeaderClick('mfr_ref_number')}>MFR Ref #</th>
                  <th onClick={() => handleHeaderClick('price')}>Price</th>
                  <th onClick={() => handleHeaderClick('uom')}>UOM</th>
                  <th onClick={() => handleHeaderClick('qoe')}>QOE</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {items.map(item => (
                  <React.Fragment key={item.id}>
                    <tr onClick={() => handleRowClick(item.id)}>
                      <td className="truncate-text">
                        {expandedRow === item.id 
                          ? <i className="fa fa-angle-down"></i>
                          : <i className="fa fa-angle-right"></i>
                        }
                        {item.internal_ref_no}
                      </td>
                      <td className="truncate-text">{item.description_1}</td>
                      <td className="truncate-text">{item.vendor_name}</td>
                      <td className="truncate-text">{item.vendor_ref_no}</td>
                      <td className="truncate-text">{item.manufacturer_name}</td>
                      <td className="truncate-text">{item.manufacturer_ref_no}</td>
                      <td className="truncate-text">{(item.price_in_cents / 100).toFixed(2)}</td>
                      <td className="truncate-text">{item.uom}</td>
                      <td className="truncate-text">{item.quantity_of_each}</td>
                    </tr>
                    {expandedRow === item.id && (
                      <tr className="suggested-substitutes-row">
                        <td className="substitutes-td" colSpan="9">
                          <div className="suggested-substitutes-section">
                            <div className="substitute-header">
                              Suggested Substitute(s)
                            </div>
                            {suggestedSubstitutes[item.id] && suggestedSubstitutes[item.id].length > 0 ? (
                                suggestedSubstitutes[item.id].map(substitute => (
                                  <table className="substitute-table">
                                    <thead className="substitute-table-head">
                                      <tr className="substitute-table-row">
                                        <th onClick={() => handleHeaderClick('item_id')}>Item ID</th>
                                        <th onClick={() => handleHeaderClick('description')}>Description</th>
                                        <th onClick={() => handleHeaderClick('vendor_name')}>Vendor Name</th>
                                        <th onClick={() => handleHeaderClick('vendor_ref_number')}>Vendor Ref #</th>
                                        <th onClick={() => handleHeaderClick('mfr_name')}>MFR Name</th>
                                        <th onClick={() => handleHeaderClick('mfr_ref_number')}>MFR Ref #</th>
                                        <th onClick={() => handleHeaderClick('price')}>Price</th>
                                        <th onClick={() => handleHeaderClick('uom')}>UOM</th>
                                        <th onClick={() => handleHeaderClick('qoe')}>QOE</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr key={substitute.substitute_id}>
                                        <td className="truncate-text">
                                          <i 
                                            className="fa fa-pencil" 
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setSelectedSubstitute(substitute);
                                              handleAddSubstitute();
                                            }} 
                                            style={{ cursor: 'pointer' }}
                                          ></i>
                                          {substitute.internal_ref_no}
                                        </td>
                                        <td className="truncate-text">{substitute.description_1}</td>
                                        <td className="truncate-text">{substitute.vendor_name}</td>
                                        <td className="truncate-text">{substitute.vendor_ref_no}</td>
                                        <td className="truncate-text">{substitute.manufacturer_name}</td>
                                        <td className="truncate-text">{substitute.manufacturer_ref_no}</td>
                                        <td className="truncate-text">{(substitute.price_in_cents / 100).toFixed(2)}</td>
                                        <td className="truncate-text">{substitute.uom}</td>
                                        <td className="truncate-text">{substitute.quantity_of_each}</td>
                                      </tr>
                                      <tr>
                                        <td colSpan="9">
                                          {suggestedSubstitutesSupplementalInfo[substitute.substitute_id] && (
                                            <React.Fragment>
                                              {suggestedSubstitutesSupplementalInfo[substitute.substitute_id].requested_by_first_name && (
                                                <>
                                                  Substitute requested by <span style={underlineStyle}>
                                                    {suggestedSubstitutesSupplementalInfo[substitute.substitute_id].requested_by_first_name} {suggestedSubstitutesSupplementalInfo[substitute.substitute_id].requested_by_last_name}
                                                  </span> on <span style={underlineStyle}>
                                                    {formatUtcToLocal(suggestedSubstitutesSupplementalInfo[substitute.substitute_id].requested_datetime).date}
                                                  </span> at {formatUtcToLocal(suggestedSubstitutesSupplementalInfo[substitute.substitute_id].requested_datetime).timeAndZone}
                                                </>
                                              )}

                                              {/* Check if both are present and insert a line break */}
                                              {suggestedSubstitutesSupplementalInfo[substitute.substitute_id].requested_by_first_name && suggestedSubstitutesSupplementalInfo[substitute.substitute_id].user_creator_first_name && <br />}
      
                                              {suggestedSubstitutesSupplementalInfo[substitute.substitute_id].user_creator_first_name && (
                                                <>
                                                  Substitute added by <span style={underlineStyle}>
                                                    {suggestedSubstitutesSupplementalInfo[substitute.substitute_id].user_creator_first_name} {suggestedSubstitutesSupplementalInfo[substitute.substitute_id].user_creator_last_name}
                                                  </span> on <span style={underlineStyle}>
                                                    {formatUtcToLocal(suggestedSubstitutesSupplementalInfo[substitute.substitute_id].fulfilled_datetime).date}
                                                  </span> at {formatUtcToLocal(suggestedSubstitutesSupplementalInfo[substitute.substitute_id].fulfilled_datetime).timeAndZone}
                                                </>
                                              )}
                                            </React.Fragment>
                                          )}
                                          <button onClick={(e) => {
                                            e.stopPropagation();
                                            setSelectedSubstitute(substitute);
                                            handleEditNote();
                                          }}> Notes
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                </table>
                              ))
                            ) : (
                              <React.Fragment>
                                  <tr className="no-suggested-substitutes"> <td colSpan="9">
                                  There are no suggested substitutes for this item yet.<button onClick={(e) => {
                                    e.stopPropagation();
                                    handleAddSubstitute();
                                  }}>ADD NEW SUBSTITUTE
                                  </button>

                                  </td>
                                </tr>
                                <tr className="no-suggested-substitutes">
                                  <td colSpan="9">
                                    There are no suggested substitutes for this item yet.
                                    <button onClick={(e) => {
                                      e.stopPropagation();
                                      handleRequestSubstitute();
                                    }}>REQUEST SUBSTITUTE
                                    </button>
                                  </td>
                                </tr>
                              </React.Fragment>
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {renderNotesModal()}
      {renderAddSubstitutesModal()}
    </>
  );
}

export default SubstituteDashboard;

