import React, { useState, useEffect} from 'react';
import { Link , useOutlet } from 'react-router-dom';
import './MainLayout.css'
import logo from './assets/images/logo_transparent.png'
import Cookies from 'js-cookie'

function MainLayout() {
    const outlet = useOutlet();
    const [userDisplayName, setUserDisplayName] = useState('');

    useEffect(() => {
        const firstName = localStorage.getItem("firstName");
        const middleName = localStorage.getItem("middleName");
        const lastName = localStorage.getItem("lastName");

        let name = firstName;
        // Append middle name if it exists
        if (middleName) {
            name += ` ${middleName}`;
        }

        // Append last name
        name += ` ${lastName}`;

        setUserDisplayName(name);
        
    }, []);

    return (
        <div className="layout-container">
            <header className="layout-header">
                <img src={logo} alt="EfficientSC" className="logo" />
                <div className="user-greeting">Hello, {userDisplayName}</div>
            </header>

            <div className="layout-body">
                <aside className="sidebar">
                    <Link to="/substitute-dashboard" className="sidebar-button">All Items</Link>
                    <Link to="/requested-substitutes-dashboard" className="sidebar-button">Requested Substitutes</Link>
                    <Link to="/analytics" className="sidebar-button">Analytics</Link>
                    <Link to="/profile" className="sidebar-button">Profile</Link>
                </aside>

                <main className="layout-content">
                    {outlet}
                </main>
            </div>
        </div>
    );
}

export default MainLayout;