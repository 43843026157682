import React, { useState, useEffect, useRef } from 'react';
import './MultiSelectDropdown.css'

const MultiSelectDropdown = ({ options, onSelectionChange }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        // This function checks if the click is outside of the dropdown and closes it if true
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        if (onSelectionChange) {
            onSelectionChange(selectedOptions);
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [selectedOptions, onSelectionChange]);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleCheckboxChange = (optionValue) => {
        setSelectedOptions((prevSelectedOptions) => {
            const newSelectedOptions = prevSelectedOptions.includes(optionValue)
                ? prevSelectedOptions.filter((value) => value !== optionValue)
                : [...prevSelectedOptions, optionValue];

            // This is where we call the onSelectionChange prop with the new state
            if (onSelectionChange) {
                onSelectionChange(newSelectedOptions);
            }

            return newSelectedOptions;
        });
    };
    
    let displayValue = selectedOptions.length === options.length ? 'All' :
        selectedOptions.length > 1 ? 'Many' :
            selectedOptions.length === 1 ? selectedOptions[0] : 'MANUFACTURER (ALL)';

    return (
        <div ref={dropdownRef} className="multi-dropdown-wrapper">
            <button onClick={toggleDropdown} className='multi-dropdown-button'>
                {displayValue}
            </button>
            <i className="fa fa-angle-down" aria-hidden="true"></i>
            {isDropdownOpen && (
                <ul className="multi-dropdown-ul">
                    {options.map(option => (
                        <li key={option} className="multi-dropdown-li">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={selectedOptions.includes(option)}
                                    onChange={() => handleCheckboxChange(option)}
                                />
                                {option}
                            </label>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default MultiSelectDropdown;