import React, { useState } from 'react';
import './App.css'; // Assuming you have an App.css for styling
import LoginPage from './LoginPage'; 
import SubstituteDashboard from './SubstituteDashboard';
import RequestedSubstitutesDashboard from './RequestedSubstitutesDashboard';
import MainLayout from './MainLayout';
import 'typeface-inter';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  if (isLoggedIn) {
    return (
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route index path="substitute-dashboard" element={<SubstituteDashboard />} />
              <Route path="requested-substitutes-dashboard" element={<RequestedSubstitutesDashboard />} />
              <Route path="/flagged-items" element={<MainLayout/>} />
              <Route path="/analytics" element={<MainLayout/>} />
              <Route path="/profile" element={<MainLayout/>} />
            {/* This route redirects users to substitute-dashboard by default when logged in */}

              <Route path="/*" element={<Navigate to="/substitute-dashboard" />} />     
            </Route>
          </Routes>
        </div>
      </Router>
    );
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<LoginPage setIsLoggedIn={setIsLoggedIn}/>} />
          <Route path="/*" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;