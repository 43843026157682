import React, { useState , useEffect } from 'react';
import './LoginPage.css'
import logo from './assets/images/logo_transparent.png'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

function LoginPage(props) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  // Check if the user is already logged in
  useEffect(() => {
    const jwtToken = Cookies.get('jwtToken');
    if (jwtToken) {
      props.setIsLoggedIn(true);
      navigate('/substitute-dashboard');
    }
  }, []);

  const handleLogin = async () => {
    try {
        const formData = new URLSearchParams();
        formData.append('username', username);
        formData.append('password', password);

        const response = await fetch(`${apiUrl}/auth/token`, {    
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData
        });

        // Check if the request was successful
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        
        const data = await response.json();
        
        if (data.token_type === "bearer") {
          Cookies.set('jwtToken', data.access_token, { expires : 2});
          Cookies.set('userId', data.user_id, { expires : 2});
          fetchUserInfo(data.user_id);
          props.setIsLoggedIn(true)
          navigate('/');
        } else {
            alert(data.message);  // Display the message as an alert
        }

    } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
        alert('Error during login. Please try again.');
    }
  };

  const fetchUserInfo = async (userId) => {
    const endpoint = `${apiUrl}/users/get_user_info?user_id=${userId}`;
    const response = await fetch(endpoint);
    const data = await response.json();
    Cookies.set('userRole', data.user_role, { expires : 2});
    Cookies.set('organizationId', data.organization_id, { expires : 2});
    
    localStorage.setItem('firstName', data.first_name);
    if (data.middle_name) {
      localStorage.setItem('middleName', data.middle_name);
    }
    localStorage.setItem('lastName', data.last_name);
  }

  return (
    <div className="login-container">
      <div className="login-page">
        <img src={logo} alt="EfficientSC" className="efficientsc-logo" />
        <h2>Log in to your account</h2>
        <h3>Welcome back! Please enter your details</h3>
        <div className="login-field">
          <label htmlFor="username">Email</label>
          <input
            className="uniform-height rounded-border"
            type="text"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
        </div>
        <div className="login-field">
          <label htmlFor="password">Password</label>
          <input
            className="uniform-height rounded-border"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>
        <button className="uniform-height rounded-border" onClick={handleLogin}>Sign In</button>
      </div>
    </div>
  );
}

export default LoginPage;