import React, { useState, useEffect } from 'react';
import './AddSubstituteModal.css'
import Cookies from 'js-cookie';

function AddSubstituteModal(props) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { item, selectedSubstitute, onClose } = props;
    const [vendorName, setVendorName] = useState('');
    const [vendorRef, setVendorRef] = useState('');
    const [mfrName, setMfrName] = useState('');
    const [mfrRef, setMfrRef] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [uom, setUom] = useState('');
    const [qoe, setQoe] = useState('');
    const [internalRefNo, setInternalRefNo] = useState('');
    const [textContent, setTextContent] = useState('');
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [orgId, setOrgId] = useState(Cookies.get("organizationId"));
    const [manufacturers, setManufacturers] = useState({ all_fields: [] });
    const [vendors, setVendors] = useState({ all_fields: [] });
    const [unitsOfMeasure, setUnitsOfMeasure] = useState({ all_fields: [] });
    const [isLoading, setIsLoading] = useState(true);
    const [userNoteId, setUserNoteId] = useState('');

    useEffect(() => {
        // Check if selectedSubstitute exists and is not null
        if (props.selectedSubstitute && Object.keys(props.selectedSubstitute).length > 0) {
            setVendorName(props.selectedSubstitute?.vendor_name || '');
            setVendorRef(props.selectedSubstitute?.vendor_ref_no || '');
            setMfrName(props.selectedSubstitute?.manufacturer_name || '');
            setMfrRef(props.selectedSubstitute?.manufacturer_ref_no || '');
            setDescription(props.selectedSubstitute?.description_1 || '');
            setPrice((props.selectedSubstitute?.price_in_cents / 100).toFixed(2) || '');
            setUom(props.selectedSubstitute?.uom || '');
            setQoe(props.selectedSubstitute?.quantity_of_each || '');
            setInternalRefNo(props.selectedSubstitute?.internal_ref_no || '');
            setSelectedLocation(props.selectedSubstitute?.location_id || '');
            getUserNoteHistory();
        }
        setIsLoading(false);
      }, [props.selectedSubstitute]);
    
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                // Fetch locations
                const locationsResponse = await fetch(`${apiUrl}/locations/get_locations_by_org_id?org_id=${orgId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                const locationsData = await locationsResponse.json();
                setLocations(locationsData.locations);

                // Fetch unique values for manufacturer_name, vendor_name, and uom
                const fetchUniqueValues = async (fieldName) => {
                    const endpoint = `${apiUrl}/items/unique_values/${fieldName}`;
                    const response = await fetch(endpoint);
                    const data = await response.json();

                    if (fieldName === 'manufacturer_name') {
                        setManufacturers(data);
                    } else if (fieldName === 'vendor_name') {
                        setVendors(data);
                    } else if (fieldName === 'uom') {
                        setUnitsOfMeasure(data);
                    }
                };

                await Promise.all([
                    fetchUniqueValues('manufacturer_name'),
                    fetchUniqueValues('vendor_name'),
                    fetchUniqueValues('uom')
                ]);

            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [orgId]); // Added orgId as a dependency to refetch if orgId changes

    const editSubstitute = async () => {
        const validNumber = /^[0-9]+(\.[0-9]{1,2})?$/.test(price);
        if (!validNumber) {
            alert('Please enter a valid price. Up to two decimal places are allowed.');
            return;
        }

        const user_creator_id = Cookies.get("userId");

        // Convert price to cents
        const priceInCents = (parseFloat(price) * 100).toFixed(0);
        
        try {
            const requestBody = {
                substitute_id: selectedSubstitute.substitute_id,
                substitute_item_info: {
                    id: selectedSubstitute.id,
                    internal_ref_no: internalRefNo,
                    description_1: description,
                    manufacturer_name: mfrName,
                    manufacturer_ref_no: mfrRef,
                    uom: uom,
                    quantity_of_each: qoe,
                    vendor_name: vendorName,
                    vendor_ref_no: vendorRef,
                    price_in_cents: priceInCents
                },
                location_id: selectedLocation,
                user_note_info: {
                    user_creator_id: user_creator_id,
                    last_edited_by_id: user_creator_id, 
                    text_content: textContent,
                    note_type: "SUBSTITUTE"
                },
            }

            const response = await fetch(`${apiUrl}/substitutes/update`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });
            

            // Check if the request was successful
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (data.status == "SUCCESS") {
                alert("Updated substitute successfully");
                onClose();
            }
        } catch (error) {
            alert("Error fetching data: ", error);
        } finally {
            setIsLoading(false);
        }
    }

    const getUserNoteHistory = async () => {
        setIsLoading(true);
        try {
            const substituteId = selectedSubstitute.substitute_id
            const endpoint = `${apiUrl}/user_notes/get_user_note_history?substitute_id=${substituteId}`
            const response = await fetch(endpoint);
            const data = await response.json();
            if (data && data.status == "SUCCESS") { 
                setUserNoteId(data.user_note_id);
                setTextContent(data.current_note_text);
            }
        } catch (error) {
            console.error("Error fetching user note history: ", error)
        } finally {
            setIsLoading(false);
        }
    }   


    const createSubstitute = async () => {
        const validNumber = /^[0-9]+(\.[0-9]{1,2})?$/.test(price);
        if (!validNumber) {
            alert('Please enter a valid price. Up to two decimal places are allowed.');
            return;
        }

        const user_creator_id = Cookies.get("userId");

        // Convert price to cents
        const priceInCents = (parseFloat(price) * 100).toFixed(0);
        try {
            const requestBody = {
                original_item_id: item.id,
                location_id: selectedLocation,
                substitute_item_info: {
                    internal_ref_no: internalRefNo,
                    description_1: description,
                    manufacturer_name: mfrName,
                    manufacturer_ref_no: mfrRef,
                    uom: uom,
                    quantity_of_each: qoe,
                    vendor_name: vendorName,
                    vendor_ref_no: vendorRef,
                    price_in_cents: priceInCents
                },
                user_note_info: {
                    user_creator_id: user_creator_id,
                    last_edited_by: user_creator_id, 
                    text_content: textContent,
                    note_type: "SUBSTITUTE"
                },
            };
            const response = await fetch(`${apiUrl}/substitutes/create_substitute`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            // Check if the request was successful
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (data.status == "SUCCESS") {
                alert("Created substitute successfully");
                onClose();
            }

        } catch (error) {
            console.error("There was a problem with the fetch operation:", error.message);
            alert('Error during creation of substitute. Please try again.');
        }
    }

    return (
        <div className="modal-backdrop">
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <div className="panel-wrappers">
                        <p>Substitute Database Manual entry</p>
                        <div className='item-panel-wrappers'>
                            <div className="item-panel">
                                <div className="panel-title">
                                    <h2>Original Item</h2>
                                </div>
                                <div className="item-panel-row">
                                    <div className="input-group">
                                        <label>Vendor Name</label>
                                        <input disabled type="text" placeholder={item.vendor_name} />
                                    </div>
                                    <div className="input-group">
                                        <label>Vendor Ref</label>
                                        <input disabled type="text" placeholder={item.vendor_ref_no} />
                                    </div>
                                </div>
                                <div className="item-panel-row">
                                    <div className="input-group">
                                        <label>MFR Name</label>
                                        <input disabled type="text" placeholder={item.manufacturer_name} />
                                    </div>
                                    <div className="input-group">
                                        <label>MFR Ref</label>
                                        <input disabled type="text" placeholder={item.manufacturer_ref_no} />
                                    </div>
                                </div>
                                <div className="item-panel-row">
                                    <div className="input-group-long">
                                        <label>Description</label>
                                        <input disabled type="text" placeholder={item.description_1} />
                                    </div>
                                </div>
                                <div className="item-panel-row">
                                    <div className="input-group-short">
                                        <label>Price</label>
                                        <input disabled type="text" placeholder={`$${(item.price_in_cents / 100).toFixed(2)}`} />
                                    </div>
                                    <div className="input-group-short">
                                        <label>UOM</label>
                                        <input disabled type="text" placeholder={item.uom} />
                                    </div>
                                    <div className="input-group-short ">
                                        <label>QOE</label>
                                        <input disabled type="text" placeholder={item.quantity_of_each} />
                                    </div>
                                </div>
                                <div className="item-panel-row">
                                    <div className="input-group">
                                        <label>Item ID</label>
                                        <input disabled type="text" placeholder={item.internal_ref_no} />
                                    </div>
                                </div>
                            </div>
                            <div className="dividing-line">
                            </div>
                            <div className="item-panel">
                                <div className="panel-title">
                                    <h2>Substitute Item</h2>
                                </div>
                                <div className="item-panel-row">
                                    <div className="input-group">
                                        <label>Vendor Name</label>
                                        <select value={vendorName} onChange={e => setVendorName(e.target.value)}>
                                            <option value="" disabled>Select Vendor</option>
                                            {vendors.all_fields && vendors.all_fields.map((vendor) => (
                                                <option key={vendor} value={vendor}>
                                                    {vendor}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="input-group">
                                        <label>Vendor Ref</label>
                                        <input type="text" placeholder="#" value={vendorRef} onChange={e => setVendorRef(e.target.value)} />
                                    </div>
                                </div>
                                <div className="item-panel-row">
                                    <div className="input-group">
                                        <label>MFR Name</label>
                                        <select value={mfrName} onChange={e => setMfrName(e.target.value)}>
                                            <option value="" disabled>Name</option>
                                            {manufacturers.all_fields && manufacturers.all_fields.map((manufacturer) => (
                                                <option key={manufacturer} value={manufacturer}>
                                                    {manufacturer}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="input-group">
                                        <label>MFR Ref</label>
                                        <input type="text" placeholder="#" value={mfrRef} onChange={e => setMfrRef(e.target.value)} />
                                    </div>
                                </div>
                                <div className="item-panel-row">
                                    <div className="input-group-long">
                                        <label>Description</label>
                                        <input type="text" placeholder="Name" value={description} onChange={e => setDescription(e.target.value)} />
                                    </div>
                                </div>
                                <div className="item-panel-row">
                                    <div className="input-group-short">
                                        <label>Price</label>
                                        <input type="text" placeholder="$" value={price} onChange={e => setPrice(e.target.value)} />
                                    </div>
                                    <div className="input-group-short">
                                        <label>UOM</label>
                                        <select value={uom} onChange={e => setUom(e.target.value)}>
                                            <option value={""} disabled>Ea, Ca, etc. </option>
                                            {unitsOfMeasure.all_fields && unitsOfMeasure.all_fields.map((uom) => (
                                                <option key={uom} value={uom}>
                                                    {uom}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="input-group-short">
                                        <label>QOE</label>
                                        <input type="text" placeholder="#" value={qoe} onChange={e => setQoe(e.target.value)} />
                                    </div>
                                </div>
                                <div className="item-panel-row">
                                    <div className="input-group">
                                        <label>Item ID</label>
                                        <input type="text" value={internalRefNo} onChange={e => setInternalRefNo(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="approved-for-row">
                        <div className="input-group">
                            <label>Approved for:</label>
                            <select value={selectedLocation} onChange={e => setSelectedLocation(e.target.value)}>
                                <option value="" disabled>Select Location</option>
                                {locations.map((location, index) => (
                                    <option key={index} value={location.id}>{location.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="input-group-long">
                            <textarea placeholder="Add notes" rows="4" value={textContent} onChange={e => setTextContent(e.target.value)}></textarea>
                        </div>
                    </div>
                    <div className="attachments-save-wrapper">
                        <button className="button" onClick={onClose}>Close</button>
                        <button className="button orange" onClick={Object.keys(selectedSubstitute).length > 0 ? editSubstitute : createSubstitute}>Save</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AddSubstituteModal;
